import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: null,
  product: {
    product_name: null,
    Product_type: null,
    Product_description: null,
    price: null,
    Image_Url: null,
    product_id: null,
  },

  favorites: [],
  // cart: [],
  patientData: [],
  InvoiceData: [],
  OrderData: [],
  BillingData: [],
  verifiedPatience: [],
  DocumentData: {},
  ChartData: [],
  DashboardCountData: {},
  total: null,
  NotificationData: [],
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setProductsAll: (state, action) => {
      state.products = action.payload.products;
    },

    getProduct: (state, action) => {
      const productId = action.payload.product_id;
      const product = state.products.find(
        (product) => product.product_id === productId
      );

      // You can store the found product in your state, so you can access it later if needed
      state.product = product;
    },

    setProduct: (state, action) => {
      state.product = action.payload.item;
    },

    setDashboardCountData: (state, action) => {
      state.DashboardCountData = action.payload.DashboardCountData;
    },
    setChartData: (state, action) => {
      state.ChartData = action.payload.ChartData;
    },

    setpatientData: (state, action) => {
      const sortedData = action.payload.patientData.sort(
        (a, b) => new Date(b.entry_date) - new Date(a.entry_date)
      );
      state.patientData = sortedData;
    },
    setUpdatepatientData: (state, action) => {
      const { bv_id, date_of_service } = action.payload;

      // Find the index of the item in the array
      const index = state.patientData.findIndex((item) => item.bv_id === bv_id);

      if (index !== -1) {
        // Create a new copy of the array
        const updatedpatientData = [...state.patientData];

        // Create a new copy of the object at the found index with updated properties
        updatedpatientData[index] = {
          ...updatedpatientData[index],
          date_of_service:
            date_of_service !== undefined
              ? date_of_service
              : updatedpatientData[index].date_of_service,
        };

        // Update the state with the new array
        state.patientData = updatedpatientData;
      }
    },
    setInvoiceData: (state, action) => {
      const sortedData = action.payload.InvoiceData.sort(
        (a, b) => new Date(b.invoice_date) - new Date(a.invoice_date)
      );
      state.InvoiceData = sortedData;
    },
    setOrderData: (state, action) => {
      const sortedData = action.payload.OrderData.sort(
        (a, b) => new Date(b.order_date) - new Date(a.order_date)
      );
      state.OrderData = sortedData;
    },
    updateOrderData: (state, action) => {
      const { order_id } = action.payload;
      // Find the index of the item in the array
      const filteredOrderData = state.OrderData.filter(
        (item) => item.order_id !== order_id
      );
      state.OrderData = filteredOrderData;
    },
    setBillingData: (state, action) => {
      //   const sortedData = action.payload.BillingData.sort(
      //   (a, b) => new Date(b.order_date) - new Date(a.order_date)
      // );
      state.BillingData = action.payload.BillingData;
    },
    setUpdateBillingData: (state, action) => {
      const { order_id, amount_billed, amount_paid, date_billed, date_paid } =
        action.payload;

      // Find the index of the item in the array
      const index = state.BillingData.findIndex(
        (item) => item.order_id === order_id
      );

      if (index !== -1) {
        // Create a new copy of the array
        const updatedBillingData = [...state.BillingData];

        // Create a new copy of the object at the found index with updated properties
        updatedBillingData[index] = {
          ...updatedBillingData[index],
          amount_billed:
            amount_billed !== undefined
              ? amount_billed
              : updatedBillingData[index].amount_billed,
          amount_paid:
            amount_paid !== undefined
              ? amount_paid
              : updatedBillingData[index].amount_paid,
          date_billed:
            date_billed !== undefined
              ? date_billed
              : updatedBillingData[index].date_billed,
          date_paid:
            date_paid !== undefined
              ? date_paid
              : updatedBillingData[index].date_paid,
        };

        // Update the state with the new array
        state.BillingData = updatedBillingData;
      }
    },
    setDocumentData: (state, action) => {
      state.DocumentData = action.payload.DocumentData;
    },

    setVerifiedpatientData: (state, action) => {
      state.verifiedPatience = action.payload.verifiedPatience;
    },
    setNotificationData: (state, action) => {
      const sortedData = action.payload.NotificationData.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      state.NotificationData = sortedData;
    },
    updateNotificationData: (state, action) => {
      const { notification_id, is_viewed } = action.payload;
      // Find the index of the item in the array
      const index = state.NotificationData.findIndex(
        (item) => item.notification_id === notification_id
      );

      if (index !== -1) {
        // Create a new copy of the array
        const updatedNotificationData = [...state.NotificationData];

        // Create a new copy of the object at the found index with updated properties
        updatedNotificationData[index] = {
          ...updatedNotificationData[index],
          is_viewed: is_viewed,
        };

        // Update the state with the new array
        state.NotificationData = updatedNotificationData;
      }
    },
  },
});

export const {
  setProductsAll,
  getProduct,
  setProduct,
  setCartProduct,
  setpatientData,
  setUpdatepatientData,
  setVerifiedpatientData,
  setInvoiceData,
  setOrderData,
  updateOrderData,
  setBillingData,
  setUpdateBillingData,
  setDocumentData,
  setChartData,
  setDashboardCountData,
  setNotificationData,
  updateNotificationData,
} = cartSlice.actions;

export default cartSlice.reducer;
