import { Route, Routes } from "react-router-dom";
import React, { lazy, Suspense, useEffect, useState } from "react";
import "react-notifications/lib/notifications.css";
import "./Styling/index.css";

import PageLoading from "./Pages/PageLoading";
import { useDispatch, useSelector } from "react-redux";
import { setLogOut, setLogoutTimer } from "./state/authSlicer";

const PrivateRoutes = lazy(() => import("./Pages/PrivateRoutes"));
const LoginAuthRoute = lazy(() => import("./Pages/LoginAuthRoute"));
const HomeLayout = lazy(() => import("./Layouts/HomeLayout/HomeLayout"));
const AccountLayout = lazy(() =>
  import("./Layouts/AccountLayout/AccountLayout")
);
const DashLayout = lazy(() => import("./Layouts/DashboardLayout/DashLayout"));
const ProductLayout = lazy(() =>
  import("./Layouts/ProductsLayout/ProductLayout")
);

const SkinSubstitutesProduct = lazy(() =>
  import("./Pages/SkinSubstitutesProduct")
);
const PracticeOptimizationServices = lazy(() =>
  import("./Pages/PracticeOptimizationServices")
);
const BillingServices = lazy(() => import("./Pages/BillingServices"));
const Home = lazy(() => import("./Pages/Home"));
const AboutUs = lazy(() => import("./Pages/AboutUs"));
const Services = lazy(() => import("./Pages/Services"));
const ContactUs = lazy(() => import("./Pages/ContactUs"));
// const BillingService = lazy(() => import("./Pages/BillingService"));
// const RevenueEnhancement = lazy(() => import("./Pages/RevenueEnhancement"));
const Locator = lazy(() => import("./Pages/Locator"));
const LoginIn = lazy(() => import("./Pages/LoginIn"));
const SignUp = lazy(() => import("./Pages/SignUp"));
const VerifyEmail = lazy(() => import("./Pages/VerificationEmail"));
const ForgotPassword = lazy(() => import("./Pages/ForgotPass"));
const ResetPassword = lazy(() => import("./Pages/ResetPass"));

const Index = lazy(() => import("./Pages/DashPages/Index"));
const BenefitsVerifications = lazy(() =>
  import("./Pages/DashPages/BenefitsVerifications")
);
const Products = lazy(() => import("./Pages/DashPages/Products"));
const Payments = lazy(() => import("./Pages/DashPages/Payments"));
const History = lazy(() => import("./Pages/DashPages/History"));
const Orders = lazy(() => import("./Pages/DashPages/Orders"));
const Documents = lazy(() => import("./Pages/DashPages/Documents"));
const Invoices = lazy(() => import("./Pages/DashPages/Invoices"));
const Patients = lazy(() => import("./Pages/DashPages/Patients"));
const Billing = lazy(() => import("./Pages/DashPages/Billing"));
// const Reports = lazy(() => import("./Pages/DashPages/Reports"));
const Favourite = lazy(() => import("./Pages/DashPages/Favourite"));
const Cart = lazy(() => import("./Pages/DashPages/Cart"));
const MainCart = lazy(() => import("./Pages/DashPages/MainCart"));
const MultiCartItems = lazy(() => import("./Pages/DashPages/MultiCartItems"));
const Information = lazy(() => import("./Pages/DashPages/Information"));
const Shipping = lazy(() => import("./Pages/DashPages/Shipping"));
const CategoryProducts = lazy(() =>
  import("./Pages/DashPages/CategoryProducts")
);
const Searched = lazy(() => import("./Pages/DashPages/Searched"));
const PersonalSetting = lazy(() => import("./Pages/DashPages/PersonalSetting"));
const Error = lazy(() => import("./Pages/Error"));

function App() {
  const { logoutTimer } = useSelector((state) => state.auth);
  const [isActive, setIsActive] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleActivity = () => {
      // User is active, reset the logout timer
      setIsActive(true);
      if (logoutTimer) {
        clearTimeout(logoutTimer);
      }
      const newLogoutTimer = setTimeout(() => {
        dispatch(setLogOut());
        // console.log("Auto logout after a certain time of inactivity");
      }, 7200000);
      // Update the logout timer in the Redux store
      dispatch(setLogoutTimer(newLogoutTimer));
    };

    // Attach event listeners for user activity
    // window.addEventListener("mousedown", handleActivity);
    window.addEventListener("keydown", handleActivity);
    window.addEventListener("scroll", handleActivity);

    return () => {
      // Clean up event listeners when the component unmounts
      // window.removeEventListener("mousedown", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      window.addEventListener("scroll", handleActivity);
    };
  }, [dispatch, logoutTimer]);

  useEffect(() => {
    // Set up a timer to check for user inactivity and log out if necessary
    const inactivityTimer = setInterval(() => {
      if (!isActive && logoutTimer) {
        dispatch(setLogOut());
        // console.log("Auto logout after a certain time of inactivity");
      }
      setIsActive(false); // User is considered inactive until proven otherwise
    }, 1000000);

    return () => {
      // Clean up the inactivity timer when the component unmounts
      clearInterval(inactivityTimer);
    };
  }, [dispatch, isActive, logoutTimer]);

  return (
    <>
      <Suspense fallback={<PageLoading />}>
        <Routes>
          <Route path="/" element={<HomeLayout />}>
            <Route index exact element={<Home />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/services-products/">
              <Route index element={<Services />} />
              {/* <Route path="billing-service" element={<BillingService />} /> */}
              {/* <Route
                path="revenue-enhancement"
                element={<RevenueEnhancement />}
              /> */}
              <Route
                path="practice-optimization-services"
                element={<PracticeOptimizationServices />}
              />
              <Route path="billing-service" element={<BillingServices />} />
              <Route
                path="skin-substitutes"
                element={<SkinSubstitutesProduct />}
              />
            </Route>
          </Route>
          <Route path="/locator" element={<Locator />} />

          <Route element={<LoginAuthRoute />}>
            <Route path="account/" element={<AccountLayout />}>
              <Route index element={<SignUp />} />
              <Route path="login/" element={<LoginIn />} />
              <Route path="verify-email/" element={<VerifyEmail />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="reset-password" element={<ResetPassword />} />
            </Route>
          </Route>

          <Route element={<PrivateRoutes />}>
            <Route path="dashboard/" element={<DashLayout />}>
              <Route path="index/" element={<Index />} />
              <Route
                path="benefits-verifications/"
                element={<BenefitsVerifications />}
              />

              <Route path="products/" element={<ProductLayout />}>
                <Route index element={<Products />} />
                <Route path="searched/:searchValue" element={<Searched />} />
                <Route
                  path="category/:categoryValue"
                  element={<CategoryProducts />}
                />
              </Route>

              <Route path="multi-items" element={<MultiCartItems />} />

              <Route path="cart/" element={<Cart />}>
                <Route path="main-cart" element={<MainCart />} />
                <Route path="information" element={<Information />} />
                <Route path="shipping" element={<Shipping />} />
              </Route>

              <Route path="favourite/" element={<Favourite />} />
              <Route path="orders/" element={<Orders />} />
              <Route path="invoices/" element={<Invoices />} />
              <Route path="documents/" element={<Documents />} />
              <Route path="patients/" element={<Patients />} />
              <Route path="claims/" element={<Billing />} />
              <Route path="reports/" element={<Index />} />
              <Route path="payments/" element={<Payments />} />
              <Route path="history/" element={<History />} />
              <Route path="setting/" element={<PersonalSetting />} />
            </Route>
          </Route>

          <Route path="*" element={<Error />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
