import React from "react";
import "../Styling/PageLoading.css";
import ReactLoading from "react-loading";

function PageLoading() {
  return (
    <>
      <div className="loadingPage">
        <ReactLoading
          type={"bars"}
          color={"#00446A"}
          height={100}
          width={100}
        />
      </div>
    </>
  );
}

export default PageLoading;
