import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  locators: [],
  favorites: [],
  cart: [],
};

export const callingSlicer = createSlice({
  name: "calling",
  initialState,
  reducers: {
    getLocatorDots: (state, action) => {
      state.locators = action.payload.locators;
      // console.log(action.payload.cart);
    },
    getCartItems: (state, action) => {
      state.cart = action.payload.cart;
      // console.log(action.payload.cart);
    },

    updateCartItems: (state, action) => {
      // console.log(action.payload.cartItem);
      const cartItem = action.payload.cartItem;

      const cartIndex = state.cart.findIndex(
        (obj) => obj.product_id === cartItem.product_id
      );

      if (cartIndex === -1) {
        // If not found, add to cart
        state.cart = [...state.cart, cartItem];
      } else {
        // If found, remove from cart
        const updatedcart = [...state.cart];
        updatedcart[cartIndex].quantity++;
        state.cart = updatedcart;
      }
    },

    IncreaseQuantityByNumber: (state, action) => {
      const cartItem = action.payload.cartItem;

      const cartIndex = state.cart.findIndex(
        (obj) => obj.product_id === cartItem.product_id
      );
      const updatedcart = [...state.cart];

      updatedcart[cartIndex].quantity = cartItem.quantity;
      state.cart = updatedcart;
    },

    deleteCartItem: (state, action) => {
      // console.log(action.payload.favItem);
      const cartItem = action.payload.cartItem;

      const cartIndex = state.cart.findIndex(
        (obj) => obj.product_id === cartItem.product_id
      );

      if (cartIndex !== -1) {
        // If not found, add to cart
        // If found, remove from cart
        const updatedcart = [...state.cart];
        updatedcart.splice(cartIndex, 1);
        state.cart = updatedcart;
      }
    },
    removeAllCartItems: (state) => {
      state.cart = [];
    },

    setFavouritesData: (state, action) => {
      state.favorites = action.payload.favorites;
    },

    addorremoveFavourite: (state, action) => {
      // console.log(action.payload.favItem);
      const favItem = action.payload.favItem;

      const favIndex = state.favorites.findIndex(
        (obj) => obj.product_id === favItem.product_id
      );

      if (favIndex === -1) {
        // If not found, add to favorites
        state.favorites = [...state.favorites, favItem];
      } else {
        // If found, remove from favorites
        const updatedFavorites = [...state.favorites];
        updatedFavorites.splice(favIndex, 1);
        state.favorites = updatedFavorites;
      }
    },
  },
});

export const {
  getCartItems,
  updateCartItems,
  IncreaseQuantityByNumber,
  deleteCartItem,
  removeAllCartItems,
  setFavouritesData,
  addorremoveFavourite,
  getLocatorDots,
} = callingSlicer.actions;

export default callingSlicer.reducer;
